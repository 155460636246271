import React from "react"
import { ThemeContext } from "../provider.js"
import Head from "../components/head"
import SEO from "../components/seo"
import styles from "./index.module.css"
import { Link } from "gatsby"
import ThemeToggle from "../components/theme-toggle"
import Footer from "../components/footer"
import { FaMapMarkerAlt } from "react-icons/fa"

export default function Home() {
  return (
    <ThemeContext.Consumer>
      {context => (
        <div id="page" className={context.isDark ? "darkTheme" : ""}>
          <Head />
          <SEO title="Erica Norby" />
          <main>

            <header id="page-header" className="maxWidth flex">
              <ThemeToggle />
            </header>

            <div id="content" className="maxWidth center">

              <div className={styles.intro}>
                <div className={styles.headingWrapper}>
                  <img src="/images/erica.JPG?v=2" alt="Erica Norby" className={styles.profilePhoto} />
                  <h1 className={styles.heading}>Erica Norby</h1>
                  <div className={styles.title}>Full Stack Developer</div>
                  <div className={styles.location}><FaMapMarkerAlt className={styles.icon} /> Northern Virginia</div>
                </div>
                <div className="buttonWrapper">
                  <Link to="/experience" className="button">Experience</Link>
                  <Link to="/projects" className="button">Projects</Link>
                </div>
              </div>

            </div>

          </main>

          <Footer />

        </div>
      )}
    </ThemeContext.Consumer>
  )
}
